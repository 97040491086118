import React, { useState, useEffect } from 'react'; import ReactGA from 'react-ga4';
import WebSocketClient from './Components/WebSocketClient';
import ChartComponent from './Components/ChartComponent';
import useWebSocketDataStore from "./Store/WebSocketDataStore.jsx";
import TablesComponent from "./Components/TablesComponent";
import './App.css';
import CorrelationComponent from "./Components/TrendComponent.jsx";
import { useMemberstack } from "@memberstack/react";  // Import the useMemberstack hook

const allowedPlanIds = [
  'prc_legacy-membership-bg54063e',  
  'prc_free-trial-legacy-membership-vi20zyi',  
  'pln_vip-membership-c0ea0lhm',
  // 'pln_72-hour-free-trial-k8d606hc'
];

function App() {
  const { data, callData, putData } = useWebSocketDataStore();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);
  const [loadingMember, setLoadingMember] = useState(true);  // Track member loading state
  const [member, setMember] = useState(null);  // Store member information
  const memberstack = useMemberstack();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch the current member and check their plan
  useEffect(() => {
    memberstack.getCurrentMember()
      .then(({ data: member }) => {
        if (!member) {
          // If no member is found, redirect to the denied page
          window.location.href = 'https://www.spxgamma.com/denied';
        } else {
          // Check if the member's plan is allowed and active or trialing
          const planConnections = member.planConnections;
          const hasValidPlan = planConnections?.some(plan => {
            const planId = plan.payment?.priceId || plan.planId; // Handle both paid and free plans
            return (
              (plan.status === 'ACTIVE' || plan.status === 'TRIALING') && 
              allowedPlanIds.includes(planId) // Require active or trialing status
            );
          });
  
          if (hasValidPlan) {
            setMember(member);  // Set the member info
            setLoadingMember(false);  // Stop loading
            console.log("Current member with valid plan (ACTIVE or TRIALING):", member);
          } else {
            // Redirect if the member doesn't have a valid plan
            window.location.href = 'https://www.spxgamma.com/denied';
          }
        }
      })
      .catch(err => {
        console.error("Error fetching current member:", err);
        window.location.href = 'https://www.spxgamma.com/denied';  // Redirect on error
      });
  }, [memberstack]);

  // While member info is loading, show a loading message
  if (loadingMember) {
    return <div>Loading...</div>;  // Display a loading screen while checking membership
  }

  return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        <WebSocketClient />
        <div style={{ width: '100%', padding: '0px' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '80vh',
            padding: '20px'
          }}>
            <img
              src="/logo192.png"
              alt="Logo"
              style={{
                marginBottom: '2rem',
                width: '192px',
                height: '192px'
              }}
            />
            <div style={{
              maxWidth: '800px',
              textAlign: 'center',
              color: '#B18800',
              fontSize: '24px',
              lineHeight: '1.6'
            }}>
              <h2 style={{
                color: '#B18800',
                marginBottom: '1.5rem',
                fontSize: '36px'
              }}>
                Platform Upgrade Notice
              </h2>
              <p style={{ marginBottom: '1.5rem' }}>
                We're excited to announce that we've upgraded our SPX 0 DTE Dashboard with Trading View Advanced Charts to provide you with an enhanced trading experience.
              </p>
              <p style={{ marginBottom: '1.5rem' }}>
                You can access our platforms here:
              </p>
              <div style={{ marginBottom: '1rem' }}>
                <a
                  href="https://spxgamma-spx-0-dte-dashboard.spxgamma.com/"
                  style={{
                    color: '#B18800',
                    textDecoration: 'none',
                    fontWeight: '500',
                    borderBottom: '1px solid #B18800'
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  New SPX 0 DTE Dashboard with Trading View Charts
                </a>
              </div>
              <div>
                <a
                  href="https://spxgamma-0dte-live-advanced-flows.spxgamma.com/"
                  style={{
                    color: '#B18800',
                    textDecoration: 'none',
                    fontWeight: '500',
                    borderBottom: '1px solid #B18800'
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Legacy 0 DTE Advanced Flows Dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default App;




//Current layout. Keep as a backup.
// import React, { useState, useEffect } from 'react';
// import ReactGA from 'react-ga4';
// import WebSocketClient from './Components/WebSocketClient';
// import ChartComponent from './Components/ChartComponent';
// import useWebSocketDataStore from "./Store/WebSocketDataStore.jsx";
// import TablesComponent from "./Components/TablesComponent";
// import './App.css';
// import CorrelationComponent from "./Components/TrendComponent.jsx";
//
//
// function App() {
//   const { data, callData, putData } = useWebSocketDataStore();
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);
//
//   useEffect(() => {
//     // Initialize Google Analytics
//     ReactGA.initialize('G-3T651JWGNW');
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 1080);
//     };
//
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
//
//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//       <WebSocketClient />
//       <div style={{
//         display: 'flex',
//         flexDirection: isMobile ? 'column' : 'row',
//         justifyContent: 'center',
//         width: '100%',
//         padding: '0px',
//       }}>
//         <div style={{ flex: 1, padding: '5px', alignItems: 'center', marginBottom: '40px' }}>
//           <ChartComponent data={data} />
//           <CorrelationComponent />
//         </div>
//         <div style={{ flex: 1, marginRight: '0px', marginBottom: '20px', alignItems: 'center' }}>
//           <TablesComponent callData={callData} putData={putData} />
//         </div>
//       </div>
//     </div>
//   );
// }
//
// export default App;


